<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="表单管理"
      class="px-5 py-3 mb-5"
    >
      <div class="my-5">
        <v-btn
          color="success"
          @click="createFormBtn"
        >
          <v-icon left>
            fa fa-plus
          </v-icon>
          创建表单
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>#</th>
            <th>名称</th>
            <th>分类</th>
            <th>创建时间</th>
            <th>排序</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(form, index) in forms"
            :key="form.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ form.name }}</td>
            <td>{{ form.type }}</td>
            <td>{{ form.createdAt | dateformat }}</td>
            <td>{{ form.weight }}</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="info"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-list-ol
                </v-icon>
                审核流程
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="success"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-edit
                </v-icon>
                表单项
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="copyFormBtn(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-copy
                </v-icon>
                复制
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="editFormBtn(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
                @click="delForm(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div>
    </base-material-card>
    <v-dialog
      v-model="createFormDialog"
      max-width="1000px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>表单信息</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="createFormDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="name"
                    label="表单名称"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="code"
                    label="表单代码"
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-select
                    v-model="type"
                    :items="formClasss"
                    item-text="name"
                    item-value="value"
                    label="表单类型"
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-select
                    v-model="categoryId"
                    :items="formTypes"
                    item-text="name"
                    item-value="code"
                    label="所属分类"
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-select
                    v-model="parentId"
                    :items="parents"
                    item-text="name"
                    item-value="code"
                    label="父级表单"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="weight"
                    label="排序"
                    hint="排序数值越大越靠后"
                    persistent-hint
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-menu
                    v-model="fillEndAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="fillEndAt"
                        clearable
                        label="填报结束时间"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="fillEndAt = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="fillEndAt"
                      @input="fillEndAtMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-menu
                    v-model="auditEndAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="auditEndAt"
                        clearable
                        label="审核结束时间"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="fillEndAt = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="auditEndAt"
                      @input="auditEndAtMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-menu
                    v-model="editEndAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="editEndAt"
                        clearable
                        label="编辑结束时间"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="fillEndAt = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="editEndAt"
                      @input="editEndAtMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-switch
                    v-model="publicform"
                    label="公开表单"
                    color="red"
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-switch
                    v-model="offline"
                    label="下线表单"
                    color="red"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="config"
                    label="表单配置JSON"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="createFormDialog = false"
          >
            关闭
          </v-btn>
          <v-btn
            v-if="saveForm"
            color="success"
            @click="addForm"
          >
            保存
          </v-btn>
          <v-btn
            v-if="!saveForm"
            color="success"
            @click="upForm"
          >
            编辑
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="copyFormDialog"
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>复制表单</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="copyFormDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="copyName"
                    label="表单名称"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="copyType"
                    :items="formClasss"
                    item-text="name"
                    item-value="value"
                    label="表单类型"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="copyCategoryId"
                    :items="formTypes"
                    item-text="name"
                    item-value="code"
                    label="所属分类"
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-switch
                    v-model="engine"
                    label="克隆审核引擎"
                    color="red"
                  />
                </v-col>
                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                >
                  <v-switch
                    v-model="mirror"
                    label="镜像表单"
                    color="red"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="copyFormDialog = false"
          >
            关闭
          </v-btn>
          <v-btn
            color="success"
            @click="copyForm"
          >
            复制
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import form from '../../api/form'
  import type from '../../api/type'
  export default {
    data () {
      return {
        page: 1,
        id: '',
        auditEndAtMenu: false,
        editEndAtMenu: false,
        fillEndAtMenu: false,
        createFormDialog: false,
        copyFormDialog: false,
        forms: [],
        saveForm: true,
        auditEndAt: '',
        categoryId: '',
        code: '',
        config: '{}',
        editEndAt: '',
        fillEndAt: '',
        icon: '',
        name: '',
        offline: false,
        parentId: '',
        publicform: false,
        type: '',
        weight: 0,
        formTypes: [],
        parents: [],
        formClasss: [
          {
            name: '普通表单',
            value: 'normal',
          },
          {
            name: '投资促进参考',
            value: 'magazine',
          },
          {
            name: '工单',
            value: 'work-order',
          },
          {
            name: '进展',
            value: 'evolve',
          },
          {
            name: '注册表单',
            value: 'register',
          },
        ],
        copyName: '',
        copyCategoryId: '',
        engine: false,
        mirror: false,
        copyType: '',
        siteId: '',
      }
    },
    created () {
      this.siteId = localStorage.systemId
      this.getFormes()
      this.getFormType()
    },
    methods: {
      getFormType () {
        type.getType('form')
          .then(res => {
            this.formTypes = res.data.data
          })
      },
      createFormBtn () {
        this.createFormDialog = true
        this.saveForm = true
        this.auditEndAt = ''
        this.categoryId = ''
        this.code = ''
        this.config = '{}'
        this.editEndAt = ''
        this.fillEndAt = ''
        this.icon = ''
        this.name = ''
        this.offline = false
        this.parentId = ''
        this.publicform = false
        this.siteId = 0
        this.type = ''
        this.weight = 0
        this.parents = this.forms
      },
      addForm () {
        this.id = ''
        form.addForm({
          name: this.name,
          code: this.code,
          auditEndAt: this.auditEndAt + ' 00:00:00',
          categoryId: this.categoryId,
          editEndAt: this.editEndAt + ' 00:00:00',
          fillEndAt: this.fillEndAt + ' 00:00:00',
          config: JSON.parse(this.config),
          icon: this.icon,
          offline: this.offline,
          parentId: this.parentId,
          public: this.publicform,
          siteId: this.siteId,
          type: this.type,
          weight: this.weight,
        })
          .then(res => {
            this.getFormes()
            this.createFormDialog = false
            this.$swal({
              title: '创建成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '创建失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
      editFormBtn (index) {
        this.id = this.forms[index].id
        this.parents = JSON.parse(JSON.stringify(this.forms))
        this.parents.map((val, index) => {
          if (val.id === this.id) {
            this.parents.splice(index, 1)
          }
        })
        this.createFormDialog = true
        this.saveForm = false
        const formInfo = this.forms[index]
        this.name = formInfo.name
        this.code = formInfo.code
        this.auditEndAt = formInfo.auditEndAt
        this.categoryId = formInfo.categoryId
        this.config = JSON.stringify(formInfo.config)
        this.editEndAt = formInfo.editEndAt
        this.fillEndAt = formInfo.fillEndAt
        this.icon = formInfo.icon
        this.offline = formInfo.offline
        this.parentId = formInfo.parentId
        this.public = formInfo.publicform
        this.siteId = formInfo.siteId
        this.type = formInfo.type
        this.weight = formInfo.weight
      },
      upForm () {
        form.upForm(this.id, {
          name: this.name,
          code: this.code,
          auditEndAt: this.auditEndAt + ' 00:00:00',
          categoryId: this.categoryId,
          config: JSON.parse(this.config),
          editEndAt: this.editEndAt + ' 00:00:00',
          fillEndAt: this.fillEndAt + ' 00:00:00',
          icon: this.icon,
          offline: this.offline,
          parentId: this.parentId,
          public: this.publicform,
          siteId: this.siteId,
          type: this.type,
          weight: this.weight,
        })
          .then(res => {
            this.getFormes()
            this.createFormDialog = false
            this.$swal({
              title: '修改成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '修改失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
      getFormes () {
        form.getForms()
          .then(res => {
            this.forms = res.data.data
          })
      },
      delForm (index) {
        this.$swal({
          title: '你确定要删除"' + this.forms[index].name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            form.delForm(this.forms[index].id)
              .then(res => {
                this.forms.splice(index, 1)
                this.$swal({
                  title: '删除成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      copyFormBtn (index) {
        this.id = this.forms[index].id
        this.copyFormDialog = true
        this.copyName = this.forms[index].name
        this.copyType = this.forms[index].type
        this.copyCategoryId = this.forms[index].categoryId
      },
      copyForm () {
        form.copyForm(this.id, {
          categoryId: this.copyCategoryId,
          engine: this.engine,
          mirror: this.mirror,
          name: this.copyName,
          type: this.copyType,
          siteId: this.siteId,
        })
          .then(res => {
            this.getFormes()
            this.copyFormDialog = false
            this.$swal({
              title: '复制成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
  .form_box{
    max-height: 60vh;
    overflow: auto;
  }
</style>
